import React from "react";
import client from "lib/ApolloClient";
import DataList from "components/list/DataList";
import ResourceDisplayComp from "components/cust/ResourceDisplayComp";
import GraphqlFragment from "lib/GraphqlFragment";
import { ApolloProvider } from "react-apollo";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import qs from "query-string";
import navigate from "lib/navigate";
import ResourceQueryGroupComp from "components/cust/ResourceQueryGroupComp";
import cateAPI from "lib/cateAPI";
import { inject, observer } from "mobx-react";
import ResourceListModal from "./ResourceListModal";
import ShareExportModal from "../common/ShareExportModal";
import ReactHtmlParser from "react-html-parser";

import DBResourceDetailLightBox from "components/resource/DBResourceDetailLightBox";
import JournalResourceDetailLightBox from "components/resource/JournalResourceDetailLightBox";

const getResourceList = gql`
  query getResourceList($searchForm: ResourceForm) {
    getResourceList(Input: $searchForm) {
      display {
        field
        name
        position
        type
        isUnEscape
      }
      sort {
        name
        order
        field
        default
      }
      batch {
        ...getElemtn
      }
      list {
        values {
          ref {
            key
            value
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        hyftdToken
        userCollectionList
        sortFieldList {
          type
          message
        }
        pages {
          val
          active
        }
        showNext
        showPrevious
      }
    }
  }
  ${GraphqlFragment.defaultLayoutFragment}
`;

@inject("appStore")
@observer
class ResourceListComp extends React.Component {
  constructor(props) {
    super(props);
    let { resourceConfig } = props.appStore;
    let defaultResourceType =
      resourceConfig && resourceConfig.resourceTabList
        ? resourceConfig.resourceTabList[0]
        : "databases";
    let isDatabases =
      props.appStore.globalConfig["jumperrwd.common.isDatabases"];
    let isJournals = props.appStore.globalConfig["jumperrwd.common.isJournals"];
    let ispjournals = props.appStore.globalConfig["jumper.common.ispjournals"];
    let isEbooks = props.appStore.globalConfig["jumperrwd.common.isEbooks"];
    this.state = {
      auth: props.readerStore.auth,
      dbID: "",
      journalID: "",
      resourceType: defaultResourceType,
      isAllTag: false,
      ids: [],
      showSort: false,
      showFilter: false,
      refetch: null,
      total: 0,
      checkedData: [],
      modalVisible: false,
      exportModalVisible: false,
      showDatabase: isDatabases === "1",
      showJournal: isJournals === "1",
      showPJournal: ispjournals === "1",
      showEbook: isEbooks === "1",
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      JSON.stringify(props.searchForm) !== JSON.stringify(this.props.searchForm)
    ) {
      this.setState({ ids: [] });
    }
  }

  @autobind
  handleShowSort(e) {
    e.preventDefault();
    this.setState({ showSort: !this.state.showSort });
  }

  @autobind
  changeShowType(showType, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    params.showType = showType;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  onQueryCompleted(data) {
    let { info, list } = data.getResourceList;
    let { total } = info;
    let { values } = list;

    let ids = [];
    values.map((value) => {
      let { ref } = value;
      ref.map((v) => {
        if (v.key === "id" || v.key === "journal_id") {
          ids.push(v.value);
        }
        return "";
      });
      return "";
    });

    var update = false;
    if (ids.length !== this.state.ids.length) update = true;
    for (var i = 0; i < this.state.ids.length; i++) {
      if (this.state.ids[i] !== ids[i]) {
        update = true;
      }
    }
    if (update) this.setState({ ids: ids });

    if (total !== this.state.total) {
      this.setState({ total: total });
    }
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ refetch: refetch });
  }

  @autobind
  changeSortType(sort, order, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    params.sort = sort;
    params.order = order;
    navigate([location.pathname, qs.stringify(params)].join("?"));
    this.setState({ showSort: false });
  }

  @autobind
  changeShowFilter(type) {
    if (type) {
      this.setState({ showFilter: true });
      document.body.classList.add("fix");
    } else {
      this.setState({ showFilter: false });
      document.body.classList.remove("fix");
    }
  }

  @autobind
  handleClick(type, item) {
    if (type === "addCheckedId") {
      var c = this.state.checkedData;
      if (item.checked) {
        c.push(item.value);
      } else {
        var index = c.indexOf(item.value);
        if (index !== -1) {
          c.splice(index, 1);
        }
      }
  
      this.setState({ checkedData: c });
    } else if (type === "databases") {
      this.setState({ dbID: item.id });
      this.dbDetailLightBox.popup();
      window.history.pushState(
        "",
        "",
        "/resourceDetail?resourceType=databases&id=" + item.id
      );
    } else if (type === "journal") {
      this.setState({ journalID: item.id });
      this.journalDetailLightBox.popup();
      window.history.pushState(
        "",
        "",
        "/resourceDetail?resourceType=" + item.resourceType + "&id=" + item.id
      );
    }
  }

  @autobind
  changePageType(pageType, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = {};
    params.resourceType = search.resourceType;
    params.pageType = pageType;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  changePageTypeWithClassSN(sn, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    params.resourceType = search.resourceType;
    params.pageType = "class";
    params.par = sn;
    params.sc = "1";
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  render() {
    let { searchForm, appStore, t } = this.props;
    let {
      showSort,
      dbID,
      journalID,
      showFilter,
      showDatabase,
      showJournal,
      showPJournal,
      showEbook,
    } = this.state;
    let { resourceConfig } = appStore;

    let defaultResourceType =
      resourceConfig && resourceConfig.resourceTabList
        ? resourceConfig.resourceTabList[0]
        : "databases";

    if (searchForm.resourceType === undefined || searchForm.resourceType === "")
      searchForm.resourceType = defaultResourceType;

    console.log("this.state.checkedData", this.state.checkedData);
    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <div className={showFilter ? "filter_search show_up" : "filter_search"}>
          <a
            className="accesskey"
            href="javascript:;"
            id="aL"
            accessKey="L"
            title={this.props.t("jumperrwd.common.leftSideArea")}>
            :::
          </a>
          <ResourceQueryGroupComp
            ref={(c) => (this.queryGroup = c)}
            {...this.props}
            changeShowFilter={this.changeShowFilter}
            refetch={this.state.refetch}
            showFilter={showFilter}
          />
        </div>
        <div className="search_result">
          <a
            className="accesskey"
            href="javascript:;"
            id="aC"
            accessKey="C"
            title={this.props.t("jumperrwd.common.mainContenctArea")}>
            :::
          </a>
          <h2>{t("jumperrwd.cust.resourceList")}</h2>
          <div className="tabSet">
            <ul className="tabs">
              {showDatabase && (
                <li
                  className={
                    searchForm.resourceType === "databases" ? "active" : ""
                  }>
                  <a
                    tabIndex="0"
                    role="button"
                    onClick={() => {
                      this.setState({ checkedData: [] });
                      navigate(
                        "/custList?pageType=resources&resourceType=databases"
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.setState({ checkedData: [] });
                        navigate(
                          "/custList?pageType=resources&resourceType=databases"
                        );
                      }
                    }}>
                    {t("jumper.common.leftmenu.databases")}
                  </a>
                </li>
              )}
              {showJournal && (
                <li
                  className={searchForm.resourceType === "journal" && "active"}>
                  <a
                    tabIndex="0"
                    onClick={() => {
                      this.setState({ checkedData: [] });
                      navigate(
                        "/custList?pageType=resources&resourceType=journal"
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.setState({ checkedData: [] });
                        navigate(
                          "/custList?pageType=resources&resourceType=journal"
                        );
                      }
                    }}>
                    {t("jumper.common.leftmenu.ejournals")}
                  </a>
                </li>
              )}
              {showPJournal && (
                <li
                  className={
                    searchForm.resourceType === "paper_journal" && "active"
                  }>
                  <a
                    tabIndex="0"
                    onClick={() => {
                      this.setState({ checkedData: [] });
                      navigate(
                        "/custList?pageType=resources&resourceType=paper_journal"
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.setState({ checkedData: [] });
                        navigate(
                          "/custList?pageType=resources&resourceType=paper_journal"
                        );
                      }
                    }}>
                    {t("jumper.common.leftmenu.pjournals")}
                  </a>
                </li>
              )}
              {showEbook && (
                <li className={searchForm.resourceType === "ebook" && "active"}>
                  <a
                    tabIndex="0"
                    onClick={() => {
                      this.setState({ checkedData: [] });
                      navigate(
                        "/custList?pageType=resources&resourceType=ebook"
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.setState({ checkedData: [] });
                        navigate(
                          "/custList?pageType=resources&resourceType=ebook"
                        );
                      }
                    }}>
                    {t("jumper.common.leftmenu.ebooks")}
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="function_panel">
            <div className="result_amount">
              <input
                type="checkbox"
                title={t("jumperrwd.common.selectDatabase")}
                checked={
                  this.state.ids.length === this.state.checkedData.length
                }
                onChange={(e) => {
                  if (e.target.checked)
                    this.setState({ checkedData: this.state.ids });
                  else this.setState({ checkedData: [] });
                }}
              />
              {t("jumperrwd.common.searchResult")}：
              <div className="total">
                {ReactHtmlParser(
                  t("jumperrwd.list.totalAmount", {
                    total: this.state.total,
                  })
                )}
              </div>
              <div className="filter_num">
                {ReactHtmlParser(
                  t("jumperrwd.list.checkedAmount", {
                    total: this.state.checkedData.length,
                  })
                )}
              </div>
            </div>
            <div className="function_block">
              <div className="btn_grp">
                <button
                  type="button"
                  tabIndex="0"
                  title={t("jumper.common.addtag")}
                  onClick={() => {
                    if (this.state.checkedData.length > 0)
                      this.setState({ modalVisible: true });
                    else alert(t("jumperrwd.cust.pleaseCheckOne"));
                  }}>
                  <i className="i_tag"></i>
                  <span>{t("jumper.common.addtag")}</span>
                </button>
                <button
                  type="button"
                  tabIndex="0"
                  title={t("jumperrwd.common.shareAndExport")}
                  onClick={() => {
                    if (this.state.checkedData.length > 0) {
                      this.setState({ exportModalVisible: true });
                    } else {
                      alert(t("jumperrwd.cust.pleaseCheckOne"));
                    }
                  }}>
                  <i className="i_share"></i>
                  <span>{t("jumperrwd.common.shareAndExport")}</span>
                </button>
                <button
                  type="button"
                  tabIndex="0"
                  title={t("jumperrwd.common.cancelCollection")}
                  onClick={() => {
                    if (this.state.checkedData.length > 0) {
                      if (
                        window.confirm(
                          t("jumperrwd.common.cancelCollectionConfirm")
                        )
                      ) {
                        cateAPI
                          .delListCate(
                            this.state.checkedData,
                            searchForm.resourceType
                          )
                          .then((res) => {
                            if (res !== null && res !== undefined) {
                              if (res.data.mutation.success) {
                                alert(
                                  t("jumperrwd.common.cancelCollectionSuccess")
                                );
                                this.state.refetch();
                                if (this.queryGroup)
                                  this.queryGroup.state.refetch();
                                this.setState({ checkedData: [] });
                              } else {
                                alert(
                                  t("jumperrwd.common.cancelCollectionFailed") +
                                    " " +
                                    res.data.mutation.message
                                );
                              }
                            }
                          });
                      }
                    } else {
                      alert(t("jumperrwd.cust.pleaseCheckOne"));
                    }
                  }}>
                  <i className="i_bookmark_no"></i>
                  <span>{t("jumperrwd.common.cancelCollection")}</span>
                </button>
              </div>
              <div className="option">
                <div
                  className="name"
                  tabIndex="0"
                  onClick={this.handleShowSort.bind(this)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleShowSort(e);
                    }
                  }}>
                  <a>
                    {t("jumperrwd.common.sortType")}
                    {searchForm.sort !== undefined &&
                    searchForm.sort !== null ? (
                      <>
                        :
                        {(searchForm.sort === "cretime"
                          ? t`jumper.common.addtime`
                          : t`jumper.common.table.title`) +
                          ` - ` +
                          (searchForm.order === "asc"
                            ? t`hyint.common.table.asc`
                            : t`hyint.common.table.descendent`)}
                      </>
                    ) : (
                      <>
                        :
                        {t`jumper.common.addtime` +
                          ` - ` +
                          t`hyint.common.table.descendent`}
                      </>
                    )}
                  </a>
                </div>
                {showSort && (
                  <div className="option_list">
                    <ul>
                      <li
                        tabIndex="0"
                        onClick={this.changeSortType.bind(
                          this,
                          "cretime",
                          "desc"
                        )}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeSortType("cretime", "desc", e);
                          }
                        }}>
                        <a>
                          {t`jumper.common.addtime` +
                            ` - ` +
                            t`hyint.common.table.descendent`}
                        </a>
                      </li>
                      <li
                        tabIndex="0"
                        onClick={this.changeSortType.bind(
                          this,
                          "cretime",
                          "asc"
                        )}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeSortType("cretime", "asc", e);
                          }
                        }}>
                        <a>
                          {t("jumper.common.addtime") +
                            ` - ` +
                            t`hyint.common.table.asc`}
                        </a>
                      </li>
                      <li
                        tabIndex="0"
                        onClick={this.changeSortType.bind(
                          this,
                          "title",
                          "desc"
                        )}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeSortType("title", "desc", e);
                          }
                        }}>
                        <a>
                          {t("jumper.common.table.title") +
                            ` - ` +
                            t`hyint.common.table.descendent`}
                        </a>
                      </li>
                      <li
                        tabIndex="0"
                        onClick={this.changeSortType.bind(this, "title", "asc")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeSortType("title", "asc", e);
                          }
                        }}>
                        <a>
                          {t("jumper.common.table.title") +
                            ` - ` +
                            t`hyint.common.table.asc`}
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <button
                type="button"
                tabIndex="0"
                className="filter_search_btn"
                aria-label={this.props.t("jumperrwd.common.filterButton")}
                onClick={this.changeShowFilter.bind(this, true)}>
                <i className="i_filter"></i>
              </button>
            </div>
          </div>
          <div className="list_all">
            <DataList
              query={getResourceList}
              variables={{
                searchForm: {
                  custKey: this.props.searchForm.custKey,
                  resourceType:
                    this.props.searchForm.resourceType === undefined ||
                    this.props.searchForm.resourceType === ""
                      ? defaultResourceType
                      : this.props.searchForm.resourceType,
                  cate_id: parseInt(this.props.searchForm.cate_id),
                  limit: this.props.searchForm.limit,
                  pageNo: this.props.searchForm.pageNo,
                  sort: this.props.searchForm.sort,
                  order: this.props.searchForm.order,
                },
              }}
              fetchPolicy={"network-only"}
              displayComp={ResourceDisplayComp}
              pagination={"FixedPagination"}
              getRefetch={this.getRefetch}
              onQueryCompleted={this.onQueryCompleted}
              showNoData={true}
              refetchQueryGroup={
                this.queryGroup ? this.queryGroup.state.refetch : null
              }
              handleClick={this.handleClick}
              isChecked={(id) => {
                if (this.state.checkedData.includes(id)) return true;
                else return false;
              }}
            />
          </div>
        </div>
        <ResourceListModal
          visible={this.state.modalVisible}
          cate={""}
          ids={this.state.checkedData}
          resourceType={searchForm.resourceType}
          refetch={this.state.refetch}
          close={() => this.setState({ modalVisible: false })}
        />
        <ShareExportModal
          visible={this.state.exportModalVisible}
          ids={this.state.checkedData}
          pageType="resources"
          resourceType={searchForm.resourceType}
          close={() => this.setState({ exportModalVisible: false })}
        />
        <DBResourceDetailLightBox
          ref={(c) => {
            this.dbDetailLightBox = c;
          }}
          id={dbID}
          resourceType={"databases"}
          {...this.props}
        />
        <JournalResourceDetailLightBox
          ref={(c) => {
            this.journalDetailLightBox = c;
          }}
          id={journalID}
          resourceType={searchForm.resourceType}
          {...this.props}
        />
      </ApolloProvider>
    );
  }
}

export default ResourceListComp;
